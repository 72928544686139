import { Dropdown } from "./Dropdown";
import { ReactComponent as InvoiceIcon } from '../assets/invoice.svg'


export default function Invoices() {

    return (
        <div className="flex flex-col w-full">
            <Dropdown
                Icon={InvoiceIcon}
                menu="Invoices"
                items={["Offen", "Bezahlt"]}
            />
            <Dropdown
                Icon={InvoiceIcon}
                menu="Orders"
                items={["Parkend", "Gesendet"]}
            />
            <Dropdown
                Icon={InvoiceIcon}
                menu="Catalog"
                items={["Posters", "Categories", "Başka kategori"]}
            />
        </div>
    )
}
