import { URL as NEWURL } from '../data/Settings';
import { VENDORID as NEWVENDORID } from  '../data/Settings';

export default function fetchDataFromAPI() {

    const url = NEWURL;

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": 1221,
            "token": "062F68E5-154E-4BB5-8751-4B0D6B5DB191",
            //"slipRef": sessionStorage.getItem('slipRef'),
            "invoiceStatus": 1, //getAll gerekli mi?
            "slipStatus": 1, //0: Aktif Sepet/Slip; 1: Kayitli Sepet/Slip; 2: Gönderilmis Sepet/Slip; 3: Silinen Sepet/Slip
            "vendorId": NEWVENDORID
        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(url, requestOptions);
            const json = await response.json();
            if (json.resCode === 200) {
                console.log(requestOptions)                           
                return json;
            }
            else return;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}