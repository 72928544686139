import { URL } from '../data/Settings';
import { VENDORID } from '../data/Settings';

export default function fetchAuthentification(customerCode: string, email: string, password:string) {
    const url = URL;

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": "1115", //service for User.Auth
            "customerCode": customerCode,
            "userEmail": email,
            "password": password,
            "lang": localStorage.getItem('rcml-lang'),
            //"clientTableRef": 1,
            "deviceManifacturer": "",
            "deviceModel": "",
            "deviceSerial": "",
            "deviceOSName": "",
            "deviceOSVersion": "",
            "vendorId": VENDORID
        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(url, requestOptions);
            const json = await response.json();
            if (json.resCode === 200) {
                //sessionStorage.setItem('token', json.dataSet[0]?.token);
                //sessionStorage.setItem('discRate', json.dataSet[0]?.erpCustomerDiscRate);
               // sessionStorage.setItem('user', JSON.stringify(json.dataSet));
               
                return json;
            }
            else return;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}