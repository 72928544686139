import { Dispatch, SetStateAction, createContext } from 'react';
import { User } from './useUser';

type AuthContextType = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
}

export const AuthContext = createContext<null | AuthContextType>({
  user: null,
  setUser: () => {},
});