import './App.css';
import Footer from './pages/layout/Footer';
import Main from './pages/layout/Main';
import Header from './pages/layout/Header';
//import { LoginProvider } from './context/LoginContext';
import { AuthContext } from './authentication/AuthContext';
//import { useAuth } from './authentication/useAuth';
import { useState } from 'react';
import { User } from './authentication/useUser';

function App() {
 // const { user, login, logout } = useAuth();
  const [user, setUser] = useState<User | null>(null);

  return (
    <div className='flex flex-col h-screen w-screen bg-purple-50/50'>
      <AuthContext.Provider value={{ user, setUser}}>
        <Header />
        <Main />
        <Footer />
      </AuthContext.Provider>
    </div>
  );
}

export default App;