import Button from "../../components/Button";
import InvoiceTable from "../../components/InvoiceTable";
import Menu from "./Menu";

export default function Main() {

  return (
    <main className="mb-auto flex-grow lg:p-2 text-slate-700">
      <table className="table-fixed border-separate">
        <thead>
          <tr>
            <th></th>
            <th className="flex justify-start items-center gap-4">
              <Button />
              <Button />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top"><Menu /></td>
            <td valign="top" className="w-full">
              <InvoiceTable /></td>
          </tr>
        </tbody>
      </table>
    </main>
  )
}
