import React from 'react'
import { ReactComponent as ArrowDown } from '../assets/arrow-down.svg'

type MenuProps = {
    menu: string,
    items: string[],
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const Dropdown = ({ menu, items, Icon }: MenuProps) => {

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    return (
        <div className='w-full relative flex gap-2 flex-col' >
            <button
                onClick={handleOpen}
                className="flex flex-row w-full items-center p-2 justify-between"
                type="button"
            >
                <div className='flex fill-slate-500 gap-2'><Icon className='w-6'/>{menu}</div>
                <span className="w-6">
                    <ArrowDown />
                </span>
            </button>
            {open ?
                <ul className='pl-8 flex flex-col gap-2' >
                    {items?.map((record, index) => {
                        return (
                            <li key={index}><button >{record}</button></li>
                        );
                    }
                    )}
                </ul>
                : null}
        </div>
    )
}
