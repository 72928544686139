import fetchAuthentification from './fetchAuthentication';
import { useAuth } from './useAuth';

const Login = () => {
  const { login } = useAuth();
  const email = "info@demka.de";
  const customerCode = "1074903X"

  const handleLogin = () => {
    fetchAuthentification(customerCode, email, "0000")
      .then(json => {
        login({
          id: json.dataSet,
          name: json.dataSet[0]?.userName,
          email: email,
          authToken: json.dataSet[0]?.token
        });
      })

  };

  return (
    <div>    
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;