import { useContext, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useLocalStorage } from './useLocalStorage';

export interface User {
  id: string | null;
  name: string | null;
  email: string | null;
  authToken?: string | null;
}

export const useUser = () => {
  const info = useContext(AuthContext);
  const { setItem } = useLocalStorage();
 

  const addUser = (user: User) => {
    info?.setUser(user);
    console.log( info?.user?.authToken)
    setItem('user', JSON.stringify(user));
  };

  const removeUser = () => {
    info?.setUser(null);
    setItem('user', '');
  };

  return { addUser, removeUser };
};