import { useContext } from 'react';
import Login from '../../authentication/Login'
import LogoutButton from '../../authentication/LogoutButton'
import { useAuth } from '../../authentication/useAuth'
import { AuthContext } from '../../authentication/AuthContext';

export default function Header() {
  const info = useContext(AuthContext);

  return (
    <header className='flex flex row w-full bg-indigo-400 shadow-lg p-2 text-white font-bold shadow-inner justify-between' >
      <h3>HelpDesk</h3>
      <div>{info?.user?.name}</div>
      <Login />
      <LogoutButton />

    </header>
  )
}
