import React, { useState, useEffect } from 'react'
import { DIGITS, CURRENCY } from '../data/Settings';
import fetchDataFromAPI from '../hooks/useFetchDataFromAPI';
import toGermanDate from '../utils/toGermanDate';
import { ReactComponent as ArrowDown } from '../assets/arrow-down.svg'

type resultProps = {
    recordCount: number;
    dataSet: [{
        invoiceDate: Date;
        dueDate: Date;
        dueDay: number;
        invoiceNr: string;
        invoiceType: string;
        invoiceAmount: number;
        coveredAmount: number;
        remainingAmount: number;
    }]
}

export default function InvoiceTable() {
    const [data, setData] = useState<resultProps>();
    const [value, setValue] = useState(false);


    useEffect(() => {
        fetchDataFromAPI() //token, slipStatus
            .then(res => { setData(res); setValue(false); console.log(res) });
    }, []);
    return (
        <>
            {data && value === false
                ?
                <div className='flex flex-col w-full overflow-auto text-very-small lg:text-xs font-bold'>
                    <div className='sticky bottom-0 bg-transparent my-2'>
                        <div className='flex'>
                            <p>RecordCount: {data.recordCount}</p>
                        </div>
                    </div>
                    <table className='table-auto border-2 bg-white w-full shadow-xl border-separate border-spacing-2 rounded-lg '>
                        <thead className='sticky top-0 bg-white'>
                            <tr>
                                <th className='w-6'><ArrowDown /></th>
                                <th align="center"  className='table-cell'><div className='flex w-6 justify-center'><input type="checkbox" className="default:ring-2" /></div></th>
                                <th >InvoiceDate</th>
                                <th className='hidden lg:table-cell'>DueDate</th>
                                <th className='hidden lg:table-cell'>DueDays</th>
                                <th >InvoiceNumber</th>
                                <th className='hidden lg:table-cell'>InvoiceType</th>
                                <th >invoiceAmount ({CURRENCY})</th>
                                <th className='hidden lg:table-cell'>PaidAmount ({CURRENCY})</th>
                                <th className='hidden lg:table-cell'>OpenAmount ({CURRENCY})</th>
                                <th className='hidden lg:table-cell'>pdf</th>
                            </tr>
                        </thead>
                        <tbody className='overflow-y-auto '>
                            {data.dataSet?.map((record, index) => {
                                return (
                                    <tr key={index} className="odd:bg-indigo-200 even:bg-slate-200">
                                        <td className=''><ArrowDown /></td>
                                        <td align="center" className='table-cell'><div className='flex w-6 justify-center items-center'><input type="checkbox" className="default:ring-2 ..." /></div></td>
                                        <td>
                                            {toGermanDate(record.invoiceDate)}
                                        </td>
                                        <td className='hidden lg:table-cell'>
                                            {toGermanDate(record.dueDate)}
                                        </td>
                                        <td className='hidden lg:table-cell'>
                                            {record.dueDay}
                                        </td>
                                        <td className='text-start break-keep'>
                                            {record.invoiceNr}
                                        </td>
                                        <td className='text-start hidden lg:table-cell'>
                                            {record.invoiceType}
                                        </td>
                                        <td className='text-end pr-1'>
                                            {record.invoiceAmount.toFixed(DIGITS)}
                                        </td>
                                        <td className='text-end pr-1 hidden lg:table-cell'>
                                            {record.coveredAmount.toFixed(DIGITS)}
                                        </td>
                                        <td className='text-end pr-1 hidden lg:table-cell'>
                                            {record.remainingAmount.toFixed(DIGITS)}
                                        </td>
                                        <td className=' justify-center hidden lg:table-cell'> pdf</td>
                                    </tr>
                                );
                            }
                            )}
                        </tbody>
                    </table>
                </div>
                : null
            }</>
    )

}